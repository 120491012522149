import { defineStore, getActivePinia } from 'pinia'
import { reactive, ref, type App } from 'vue'
import { useRouter, type RouteLocationRaw } from 'vue-router'

export const usePageStore = defineStore('page', () => {
  const id = ref(0)
  const app = (getActivePinia() as any)._a as App
  const emitter = app.config.globalProperties.$emitter
  const router = useRouter()

  const cache_frame_sizes = ref(new Map<string, { width: number, height: number }>)

  function reload() {
    id.value += 1
  }

  function sweepTo(path: RouteLocationRaw) {
    id.value += 1
    router.push(path)
  }

  return { emitter, id, reload, sweepTo, cache_frame_sizes }
})

