import { type TablePresenter } from '$ui/table'
import { bva } from '$ui/utils'

export const standard = {
  root: bva('w-full caption-bottom', { }),
  caption: bva('', { }),
  header: bva('[&_tr]:border-b', { }),
  footer: bva('', { }),
  body: bva('[&>tr:last-child]:border-0', { }),
  row: bva('border-b transition-colors data-[state=selected]:bg-muted', { }),
  head: bva('h-10 px-2 text-left align-middle font-semibold', { }),
  cell: bva(`
    p-2 align-middle
    data-role-actions:text-end data-role-actions:text-nowrap data-role-actions:space-x-2
  `, { }),
  empty: bva(`
    p-4 whitespace-nowrap align-middle text-sm text-foreground
    *:data-part-inner:flex *:data-part-inner:items-center *:data-part-inner:justify-center *:data-part-inner:py-10
  `, { }),
} satisfies TablePresenter

declare module '$ui/table' {
  interface TablePresenters {
    standard: typeof standard
  }
}
