import { type PaginationPresenter } from '$ui/pagination'
import { bva } from '$ui/utils'

export const standard = {
  ellipsis: bva('w-9 h-9 flex items-center justify-center', {})
} satisfies PaginationPresenter

declare module '$ui/pagination' {
  interface PaginationPresenters {
  }
}
