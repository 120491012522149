<template>
  <PresetsConfigProvider>
    <ErrorPage v-if="error" v-bind="{ error }" />
    <RouterView v-else></RouterView>
  </PresetsConfigProvider>
</template>

<script setup lang="ts">
import ErrorPage from '$$/pages/error.vue'
import { onErrorCaptured, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useSessionStore } from './store/session'
import { usePageStore } from './store/page'
import { UnauthorizedError } from './requests/BaseRequest'
import PresetsConfigProvider from '$presets/PresetsConfigProvider.vue'

const router = useRouter()
const error = ref(null as null | Error)
const session = useSessionStore()
const page = usePageStore()

onErrorCaptured((err) => {
  if (err instanceof UnauthorizedError) {
    session.clear()
    page.sweepTo({ name: 'sign_in' })
    return false
  }

  error.value = err
  console.error(err)
  return false
})

router.afterEach((from, to) => {
  error.value = null
})
</script>
