<template>
  <slot />
</template>

<script setup lang="ts">
import * as AlertDialogPresets from './presenters/AlertDialogPresenters'
import { provideAlertDialogPresenters } from '$ui/alert-dialog'
provideAlertDialogPresenters(AlertDialogPresets)

import * as AlertPresets from './presenters/AlertPresenters'
import { provideAlertPresenters } from '$ui/alert'
provideAlertPresenters(AlertPresets)

import * as BadgePresenters from './presenters/BadgePresenters'
import { provideBadgePresenters } from '$ui/badge'
provideBadgePresenters(BadgePresenters)

import * as ButtonGroupPresenters from './presenters/ButtonGroupPresenters'
import { provideButtonGroupPresenters } from '$ui/button-group'
provideButtonGroupPresenters(ButtonGroupPresenters)

import * as ButtonPresenters from './presenters/ButtonPresenters'
import { provideButtonPresenters } from '$ui/button'
provideButtonPresenters(ButtonPresenters)

import * as CardPresenters from './presenters/CardPresenters'
import { provideCardPresenters } from '$ui/card'
provideCardPresenters(CardPresenters)

import * as DialogPresenters from './presenters/DialogPresenters'
import { provideDialogPresenters } from '$ui/dialog'
provideDialogPresenters(DialogPresenters)

import * as DropdownMenuPresenters from './presenters/DropdownMenuPresenters'
import { provideDropdownMenuPresenters } from '$ui/dropdown-menu'
provideDropdownMenuPresenters(DropdownMenuPresenters)

import * as InputPresenters from './presenters/InputPresenters'
import { provideInputPresenters } from '$ui/input'
provideInputPresenters(InputPresenters)

import * as NavPresenters from './presenters/NavPresenters'
import { provideNavPresenters } from '$ui/nav'
provideNavPresenters(NavPresenters)

import * as PaginationPresenters from './presenters/PaginationPresenters'
import { providePaginationPresenters } from '$ui/pagination'
providePaginationPresenters(PaginationPresenters)

import * as RadioGroupPresenters from './presenters/RadioGroupPresenters'
import { provideRadioGroupPresenters } from '$ui/radio-group'
provideRadioGroupPresenters(RadioGroupPresenters)

import * as SelectPresenters from './presenters/SelectPresenters'
import { provideSelectPresenters } from '$ui/select'
provideSelectPresenters(SelectPresenters)

import * as SimpleFormPresenters from './presenters/SimpleFormPresenters'
import { provideFormPresenters } from '$ui/simple_form'
provideFormPresenters(SimpleFormPresenters)

import * as TablePresenters from './presenters/TablePresenters'
import { provideTablePresenters } from '$ui/table'
provideTablePresenters(TablePresenters)

import * as TabsPresenters from './presenters/TabsPresenters'
import { provideTabsPresenters } from '$ui/tabs'
provideTabsPresenters(TabsPresenters)

import * as TooltipPresenters from './presenters/TooltipPresenters'
import { provideTooltipPresenters } from '$ui/tooltip'
provideTooltipPresenters(TooltipPresenters)

import * as WellPresenters from './presenters/WellPresenters'
import { provideWellPresenters } from '$ui/well'
provideWellPresenters(WellPresenters)
</script>