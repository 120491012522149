import { Type } from "@yoolabs/class-transformer"
import { type Class } from 'utility-types'

export function Klass<T>(klass: Class<T>) {
  return Type(() => klass)
}

function ToNumber() {
  return Type(() => Number)
}
export { ToNumber as Number }
