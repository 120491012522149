import { useSessionStore } from "$$/store/session"
import type { App } from "vue"

export default function(app: App) {
  const router = app.config.globalProperties.$router
  const pinia = app.config.globalProperties.$pinia

  router.beforeEach(async (to, from, next) => {
    const session = useSessionStore(pinia)

    if (!session.isSignedIn && to.meta.auth != false) {
      next("/sign_in")
    } else {
      next()
    }
  })
}
