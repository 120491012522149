import createCacheRequest from '$$/lib/createCacheRequest'
import useRequestList from '$$/lib/useRequestList'
import type { Session } from '$$/models'
import * as requests from "$$/requests"
import { defineStore, getActivePinia } from 'pinia'
import { computed, ref, type App } from 'vue'
import { useCookies } from '@vueuse/integrations/useCookies'

export const useSessionStore = defineStore('session', () => {
  const cookies = useCookies(['email'])

  const user_email = computed(() => cookies.get('email'))
  const isSignedIn = computed(() => !!user_email.value)
  function makeSignedIn(email: string) {
    cookies.set('email', email)
  }

  const { request, cleanup } = createCacheRequest()

  function clear() {
    cookies.remove('email')
    cleanup()
  }

  return { request, clear, isSignedIn, makeSignedIn, user_email }
})
