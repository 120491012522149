import { type CardPresenter } from '$ui/card'
import { bva } from '$ui/utils'

export const standard = {
  root: bva('rounded-md border bg-card-background relative flex flex-col', {}),
  header: bva('flex border-b p-4 relative items-center rounded-t-md', {}),
  footer: bva('flex border-t items-center p-4 rounded-b-md', {}),
  content: bva('p-4 grow', {}),
  description: bva('text-sm text-muted', {}),
  title: bva('', {}),
  table: bva('[&_tr>*]:first:ps-4 [&_tr>*]:last:pe-4', {}),
  topState: bva(`
    h-1 absolute top-0 w-full bg-current rounded-t-md
    before:absolute before:size-full before:-translate-x-full before:bg-clip-content
    after:absolute after:size-full after:bg-clip-content
  `, {}),
} satisfies CardPresenter

export const light = {
  ...standard,
  root: bva('rounded-md border relative', {}),
  header: bva('flex p-4 relative', {}),
  content: bva('p-4', {}),
  footer: bva('flex items-center p-4 pt-0', {
    // variant: {
    //   primary: 'border-primary bg-primary/10',
    //   destructive: 'border-destructive bg-destructive/10',
    //   secondary: 'border-secondary bg-secondary/10',
    //   tint: 'border-[color:hsl(var(--color-tint))] bg-[color:hsl(var(--color-tint)/0.1)]',
    // }
  }),
} satisfies CardPresenter


declare module '$ui/card' {
  interface CardPresenters {
    light: CardPresenter
  }
}
