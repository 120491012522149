import { type InputPresenter } from '$ui/input'
import { bva, cn } from '$ui/utils'

export const standard = {
  checkbox: bva(`
    peer size-4 shrink-0 rounded-sm border border-primary shadow align-middle mt-[-0.2em]
    focus-visible:outline-hidden focus-visible:ring-4 focus-visible:ring-ring/50
    disabled:cursor-not-allowed disabled:opacity-50
    data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground
    *:data-part-indicator:flex
    *:data-part-indicator:size-full
    *:data-part-indicator:items-center
    *:data-part-indicator:justify-center
    *:data-part-indicator:text-current
    *:data-part-indicator:stroke-3
  `, {}),
  radio(config) { return this.checkbox(config) },
  input: bva(`
    block w-full rounded-md py-0 px-3
    border border-border shadow-sm text-start
    bg-input-background text-foreground
    placeholder:text-muted
    disabled:text-silence disabled:bg-silence/5

    focus:outline-hidden focus:border-ring focus:ring-4 focus:ring-ring/30

    group-data-[state=invalid]:border-destructive group-data-[state=invalid]:ring-destructive/30
  `, {
    size: {
      sm: 'h-7 text-sm',
      default: 'h-9',
      lg: 'h-10',
    }
  }),
  select(config) { return cn(this.input(config), '') },
} satisfies InputPresenter

declare module '$ui/input' {
  interface InputPresenters {
  }
}
