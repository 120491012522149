import { type App } from "vue";

export default function install(app: App) {
  const router = app.config.globalProperties.$router

  router.onError((err) => {
    console.error(err)
    // alert(err)
  })

  app.config.errorHandler = function(err, instance, info) {
    console.error(err)
    // alert(err)
  }
}
