export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/duizd/app/javascript/main/pages/index.vue'),
    /* no children */
  },
  {
    path: '/accounts',
    /* internal name: '/accounts' */
    /* no component */
    children: [
      {
        path: '',
        name: '/accounts/',
        component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/duizd/app/javascript/main/pages/accounts/index.vue'),
        /* no children */
      },
      {
        path: ':id',
        name: '/accounts/[id]',
        component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/duizd/app/javascript/main/pages/accounts/[id].vue'),
        /* no children */
      },
      {
        path: ':id/edit',
        name: '/accounts/[id].edit',
        component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/duizd/app/javascript/main/pages/accounts/[id].edit.vue'),
        /* no children */
      },
      {
        path: 'new',
        name: '/accounts/new',
        component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/duizd/app/javascript/main/pages/accounts/new.vue'),
        /* no children */
      },
      {
        path: 'summary',
        name: '/accounts/summary',
        component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/duizd/app/javascript/main/pages/accounts/summary.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/categories',
    /* internal name: '/categories' */
    /* no component */
    children: [
      {
        path: '',
        name: '/categories/',
        component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/duizd/app/javascript/main/pages/categories/index.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/error',
    name: '/error',
    component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/duizd/app/javascript/main/pages/error.vue'),
    /* no children */
  },
  {
    path: '/inbox',
    /* internal name: '/inbox' */
    /* no component */
    children: [
      {
        path: '',
        name: '/inbox/',
        component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/duizd/app/javascript/main/pages/inbox/index.vue'),
        /* no children */
      },
      {
        path: ':channel',
        name: '/inbox/[channel]',
        component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/duizd/app/javascript/main/pages/inbox/[channel].vue'),
        /* no children */
      },
      {
        path: 'all',
        /* internal name: '/inbox/all' */
        /* no component */
        children: [
          {
            path: '',
            name: '/inbox/all/',
            component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/duizd/app/javascript/main/pages/inbox/all/index.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'mailbox',
        name: '/inbox/mailbox',
        component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/duizd/app/javascript/main/pages/inbox/mailbox.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/projects',
    /* internal name: '/projects' */
    /* no component */
    children: [
      {
        path: '',
        name: '/projects/',
        component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/duizd/app/javascript/main/pages/projects/index.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/refresh',
    name: '/refresh',
    component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/duizd/app/javascript/main/pages/refresh.vue'),
    /* no children */
  },
  {
    path: '/sign_in',
    name: 'sign_in',
    component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/duizd/app/javascript/main/pages/sign_in.vue'),
    /* no children */
    meta: {
      "auth": false
    }
  },
  {
    path: '/test',
    name: '/test',
    component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/duizd/app/javascript/main/pages/test.vue'),
    /* no children */
  }
]

export function handleHotUpdate(_router, _hotUpdateCallback) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
    import.meta.hot.data.router_hotUpdateCallback = _hotUpdateCallback
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    // call the hotUpdateCallback for custom updates
    import.meta.hot.data.router_hotUpdateCallback?.(mod.routes)
    const route = router.currentRoute.value
    router.replace({
      ...route,
      // NOTE: we should be able to just do ...route but the router
      // currently skips resolving and can give errors with renamed routes
      // so we explicitly set remove matched and name
      name: undefined,
      matched: undefined,
      force: true
    })
  })
}

