import { DisposableRequest } from "$$/lib/DisposableRequest"
import { plainToInstance } from "@yoolabs/class-transformer"
import { AxiosError, type AxiosInstance, type AxiosRequestConfig, type AxiosResponse } from "axios"
import _ from "lodash"
import qs from "qs"
import { type Subscribable } from "rxjs"
import URI from 'urijs'
import { parseTemplate } from 'url-template'

export interface RequestContext {
  $axios: AxiosInstance
}

export class UnauthorizedError extends Error {
  constructor() {
    super("Unauthorized")
  }
}

export abstract class BaseRequest<T> extends DisposableRequest<T> {
  endpoint = null! as string
  interpolations = {} as Record<string, any>
  query = {} as Record<string, any>
  method = "GET" as "GET" | "POST" | "PUT" | "PATCH" | "DELETE" | string
  headers = {}
  graph = null as 'info' | 'head' | string | null

  ctx!: RequestContext
  data: any

  setup(ctx: RequestContext, callback?: (req: this) => void) {
    this.ctx = ctx
    callback?.(this)
  }

  validateStatus(status: number) {
    return 200 <= status && status < 300
  }

  async request() {
    try {
      const config = this.buildPerformConfig(this.data)
      const resp = await this.$axios.request(config)
      const result = this.processResponse(resp)
      return result
    } catch (e) {
      return await this.processError(e)
    }
  }

  perform(data?: any): Subscribable<T> {
    this.data = data
    return this
  }

  buildPerformConfig(params) {
    const form_data = this.buildFormData(params)
    const url = this.buildUrl()

    const config = <AxiosRequestConfig>{
      url: url,
      method: this.method,
      data: form_data,
      headers: this.headers,
      validateStatus: this.validateStatus,
      signal: this.aborter.signal,
    }

    if (this.graph) {
      config.headers!["X-Resource-Graph"] = this.graph
    }

    return config
  }

  buildUrl() {
    const url = parseTemplate(this.endpoint).expand(this.interpolations)
    const uri = new URI(url)
    const query_string = qs.stringify(this.query, { arrayFormat: "brackets" })
    return uri.query(query_string).toString()
  }

  async processError(e: any): Promise<T> {
    if (e instanceof AxiosError) {
      if (e.response?.status == 401) {
        throw new UnauthorizedError()
      }
    }
    throw e
  }

  buildFormData(params) {
    const form_data = new FormData()
    for (const name in params) {
      const value = params[name]
      this.fillFormData(form_data, name, value)
    }
    return form_data
  }

  fillFormData(formData, name, value) {
    if (value instanceof File) {
      formData.append(name, value)
    } else if (_.isArray(value)) {
      for (const [index, val] of value.entries()) {
        this.fillFormData(formData, `${name}[]`, val);
      }
    } else if (_.isObjectLike(value)) {
      for (const attr in value) {
        const val = value[attr]
        this.fillFormData(formData, `${name}[${attr}]`, val);
      }
    } else {
      formData.append(name, value || "")
    }
  }

  abstract processResponse(response: AxiosResponse): T

  get $axios() { return this.ctx.$axios }

  responseToObject<T>(klass: new (...args: any[]) => T, response: AxiosResponse) {
    return plainToInstance<T, any>(klass, response.data)
  }

  responseToArray<T>(klass: new (...args: any[]) => T, response: AxiosResponse) {
    return plainToInstance<T, any[]>(klass, response.data)
  }
}
