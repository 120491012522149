import { TransactionRecord } from "./TransactionRecord"
import * as t from './transforms'

export class TransactionRecordConnection {
  id!: number
  created_at!: string
  entry_id!: number

  @t.Klass(TransactionRecord) transaction_record!: TransactionRecord
}
