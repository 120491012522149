import { type Ref } from "vue"
import { createProvideInject, createRelayPresenterConfig } from '../utils'

export interface RadioGroupPresenterConfig {
  // size?: 'xs' | 'sm' | 'default'
}

export type RadioGroupPresenter = {
  root: (config: Partial<RadioGroupPresenterConfig>) => string
  item: (config: Partial<RadioGroupPresenterConfig>) => string
  indicator: (config: Partial<RadioGroupPresenterConfig>) => string
  icon: (config: Partial<RadioGroupPresenterConfig>) => string
}

export interface RadioGroupPresenters {
}

export const {
  relayInjectPresenterConfig: relayRadioGroupPreseterConfig
} = createRelayPresenterConfig<RadioGroupPresenterConfig>('radio-group-presenter-config', {
  size: 'default',
})

export const {
  useInject: useRadioGroupPresenter,
  useProvide: provideRadioGroupPresenter
} = createProvideInject<Ref<RadioGroupPresenter>>('radio-group-presenter')

export const {
  useInject: useRadioGroupPresenters,
  useProvide: provideRadioGroupPresenters
} = createProvideInject<RadioGroupPresenters>('radio-group-presenters')
