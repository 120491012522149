import { type RadioGroupPresenter } from '$ui/radio-group'
import { bva } from '$ui/utils'

export const standard = {
  root: bva(`grid gap-2`, {}),
  item: bva(`
    size-4 inline-block overflow-hidden align-middle
    rounded-full border border-primary text-primary shadow
    focus:outline-hidden focus-visible:ring-1 focus-visible:ring-ring
    data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground
    disabled:cursor-not-allowed disabled:opacity-50
  `, {}),
  indicator: bva(`flex items-center justify-center`, {}),
  icon: bva(`size-3.5 fill-primary`, {}),
} satisfies RadioGroupPresenter

declare module '$ui/radio-group' {
  interface RadioGroupPresenters {
    standard: typeof standard
  }
}
